import React from 'react';
import { Block, Layout, Title } from '@dragonchain-dev/dragon-kit';
import { Layout as PageLayout, SEO } from '../components/_index';

const Terms = () => {
  return (
    <PageLayout>
      <SEO noFollow title="Terms | Dragonchain" description="Terms and conditions." />
      <Layout dark background="blue" header="Terms" />
      <Layout>
        <Block>
          <Title>Dragonchain Terms of Use</Title>
          <p>
            Welcome to the Dragonchain (“Dragonchain”, “Our”, “Us”, “We”) website (“Site”). From this
            Site, you or the company or other legal entity for which you have the legal authority to act
            (each, “You” or “Your”), may set up a user account (“Account”) and purchase licenses to
            Dragonchain software and development services (“Products”). The Site and the Products available
            through it are the sole property of Dragonchain. You may only use the Site and the Products if You
            agree to and comply with these Terms of Use (“Terms”) and any additional terms (“Additional
            Terms”) applicable to the specific Products You purchase, and Dragonchain’s Privacy Policy located
            at [link] (collectively, the “Terms”).
          </p>

          <p>
            THESE TERMS CREATE A LEGALLY BINDING CONTRACT BETWEEN YOU AND DRAGONCHAIN. BY CLICKING THE “I
            ACCEPT” BUTTON WHEN YOU SET UP YOUR ACCOUNT YOU ARE AGREEING TO BE BOUND BY THESE TERMS. IF YOU
            HAVE QUESTIONS ABOUT THE TERMS, YOU SHOULD CONSULT WITH YOUR LEGAL COUNSEL.
          </p>

          <p>
            IF YOU CHOOSE NOT TO ACCEPT THE TERMS OR BY TECHNICAL MEANS BYPASS/DISABLE THE “I ACCEPT” BUTTON,
            THEN BY INSTALLING, COPYING, DOWNLOADING, ACCESSING OR OTHERWISE USING THE PRODUCTS AVAILABLE
            THROUGH THIS SITE YOU AGREE TO BE BOUND BY THESE TERMS.
          </p>

          <Title tag="h3">Access to the Products</Title>
          <p>
            Dragonchain is granting You access to the Site and the Products on the express condition that You
            agree to and comply with all of the Terms.
          </p>

          <p>
            YOUR USE OF ANY PAYMENT METHOD, INCLUDING WHERE PERMITTED, ANY CRYPTOCURRENCY, IS AT YOUR OWN RISK
            AND IS SUBJECT TO THE TERMS AND CONDITIONS AND POLICIES APPLICABLE TO SUCH PAYMENT.
          </p>

          <Title tag="h3">Cancellation; Changes to Subscription Terms</Title>
          <p>
            We may cancel or suspend your Subscription at any time with immediate effect if We determine in
            Our sole discretion that You (a) have acted in a manner inconsistent with applicable laws or
            ordinances; (b) breached any of the Terms; © engaged in any fraud or abuse concerning Dragons or
            Your use of them; (d) violated any provision of the applicable Additional Terms; or (e) engaged in
            any conduct or act for which We may terminate or suspend your access to the Site. We may also take
            appropriate administrative or legal action if any of the items listed above occurs. Nothing
            contained in these Terms shall limit Dragonchain in its exercise of any legal or equitable rights
            or remedies.
          </p>

          <p>
            If We amend these Terms, We will revise the “last updated” date located at the bottom of these
            Terms. For changes to these Terms that We consider to be material, We will place a notice on the
            Site to read substantially as “Updated Terms” with a link to the changes for up to three (3) weeks
            or some other amount of time that We determine in our discretion. If You continue to use the Site,
            purchase Dragon tokens (“Dragons”) or Products, or log into your Account or in any other way after
            these Terms have been changed, You will be deemed to have read, understood and unconditionally
            consented to and agreed to such changes. The most current version of these Terms will be available
            on the Site and will supersede all previous versions of these Terms.
          </p>

          <Title tag="h3">Use of Dragons</Title>
          <p>
            Dragons may only be used to purchase Products as provided below. Dragonchain will not redeem them
            for cash and You represent and warrant that You will not use them to engage in any gambling
            activity or other activity prohibited by federal, state or local laws, rules or regulations. You
            may only redeem Dragons as provided in these Terms. Dragons will not be replaced, reissued or
            credited if lost, stolen or otherwise altered or destroyed. Once You have redeemed your Dragons,
            there are no refunds, returns or exchanges for additional Dragons, cash, or other goods and
            services.
          </p>

          <Title tag="h3">Purchasing Product Licenses and Services</Title>
          <p>
            You may purchase licenses to software products (“Software”) and/or development services
            (“Services”) from the Site by completing the applicable purchase order. By placing a purchase
            order, You agree to the Additional Terms applicable to the Software or Services covered by the
            purchase order.
          </p>

          <p>
            Dragonchain uses a flexible pricing system under which You may purchase Software licenses or
            Services by using Dragons issued with Your Subscription or by purchasing Dragons for cash or other
            Payment Method, or using certain Dragonchain-approved cryptocurrencies such as Bitcoin and
            Ethereum. You may purchase Dragons when You purchase access to Software or Services, or in advance
            and use them later to purchase access to Software and Services. However, You need to have an
            active and current Account to use Dragons to purchase Products from the Site. As a result, if Your
            Account is canceled for any reason, or if your Subscription terminated, You will no longer be able
            to use the Dragons associated with Your Account to make purchases from the Site.
          </p>

          <p>
            Dragons are interchangeable. That is, unless otherwise indicated for specific Software products or
            Services, during Your Subscription Term You may apply Dragons to any combination of Software and
            Services available from the Site. However, a specified number of Dragons is required to use the
            Software and Services and this may change over time, at Dragonchain’s sole option and without
            prior notice. As a result, the quantity of Software or Services you may purchase with Dragons may
            increase or decrease.
          </p>

          <p>
            In addition, the terms applicable to Your use of Dragons may vary depending on the Software or
            Service You purchase. Before redeeming Dragons for any Software or Service You should review the
            applicable terms and conditions as set forth in the Additional Terms. We reserve the right to
            modify, amend or revise the terms applicable to Your use of the Dragons as well as these Terms and
            the Additional Terms, even though even though these changes may affect Your ability to use the
            Dragons You have already purchased.
          </p>

          <p>
            Additional Terms Your right to access and use the Products and this Site is governed by the Terms
            and, as applicable, the Additional Terms applicable to your purchase, all of which are subject to
            change in Our sole discretion. The Additional Terms contain limitations on Your use of the
            Software and Services, including the work product that We may deliver to You as part of the
            Services. You must comply with all of these limitations and if You fail to do so Dragonchain may
            terminate or suspend Your access to and use of this Site, the Software and/or Services as provided
            in these Terms and the applicable Additional Terms. Except as expressly set out herein and in the
            applicable Additional Terms, Dragonchain retains all rights, including copyright, in the Software,
            the Services, and related work product and does not grant You any rights or license with respect
            to any of the above. Dragonchain reserves all rights not expressly granted to You in the Terms and
            the Additional Terms.
          </p>

          <Title tag="h3">Use of the System and Site</Title>
          <p>
            You shall not permit any other person to (a) expose or make any information regarding the
            Products, available to any third party without Dragonchain’s advance written consent; (b)
            reproduce, reverse engineer, or create derivative works of the Products; © use the Products for
            any unlawful purpose or for the benefit of any third party; (d) access the Site or Products
            through any automated means or engage in any activity that disrupts or interferes with the use or
            operation of the Site or the Products; (e) attempt to compromise the security of the Site or the
            Products; or (f) transmit any data to the Site or the Products that contains viruses or similar
            materials that may damage, disable or otherwise interfere with the operation of the Site or the
            Products. Further, You are responsible for ensuring that each Authorized User complies with these
            restrictions and all of the Terms.
          </p>

          <Title tag="h3">Your Data</Title>
          <p>
            You retain Your ownership rights in the data You and any Authorized User submits to Dragonchain.
            By setting up Your Account and registering as an Authorized User, You and each Authorized User
            grant Dragonchain the right to collect and use such data as necessary (a) to provide access to and
            maintain the Site and deliver the Software and Services; (b) to manage Your Dragon balance; © to
            respond to questions regarding the Site and the Products; and (d) to otherwise fulfill Our
            obligations under the Terms and the Additional Terms, as applicable. Dragonchain agrees that it
            will hold all such data in confidence and will not use it for any purpose other than as permitted
            under the Terms. Subject to applicable law, including GDPR as defined below, Dragonchain’s right
            to use data submitted as part of the sign-up or registration process or when using the Site or
            Products is perpetual and irrevocable and shall survive the termination of Your License.
            Dragonchain may collect statistical and other data regarding Your (and Authorized User’s) use of
            the Site and the Products and use such data as Dragonchain deems necessary to develop and improve
            the Site and the Products; provided, however, that Dragonchain shall not disclose such data to any
            third party unless all information identifying it to You (and any Authorized User) has been
            removed and/or such data has been combined with additional data such that it cannot be readily
            associated to You or an Authorized User (“Aggregate Data”). Dragonchain shall own all rights in
            the Aggregate Data.
          </p>

          <p>
            Personal Data and Privacy You remain solely responsible for compliance with General Data
            Protection Regulation (EU) 2016⁄679 (“GDPR”) with respect to information transmitted, stored or
            distributed through the Services and for determining the applicability of GDPR if Personal Data is
            stored, distributed or transmitted using the Services. If Dragonchain receives any Personal Data
            in connection with Your access to the Site or any Services : (a) You acknowledge and agree that
            unless otherwise agreed in writing by the parties, Personal Data provided to Dragonchain may be
            transferred or stored outside the jurisdiction where User is located in order for Dragonchain to
            perform the Services; (b) You shall ensure that You are entitled to transfer the relevant Personal
            Data to Dragonchain so that Dragonchain may lawfully use, process and transfer the Personal Data
            in accordance with these Terms on Your behalf; © Where Dragonchain is acting as a data processor,
            Dragonchain shall process the Personal Data (either itself or through a sub-processor) only in
            accordance with these Terms and any instructions given from time to time by You, which are
            necessary in order for them to comply with GDPR; and (d) You and Dragonchain shall each take
            industry standard technical and organizational measures designed to prevent unauthorized or
            unlawful processing of Personal Data or its loss, destruction or damage. For the purposes of this
            Section , the terms “processing,” “data controller,” “data processor” and “data subject” shall
            have the meanings given to them in the GDPR.
          </p>

          <Title tag="h3">Monitoring</Title>
          <p>
            The Site and Products have the ability to monitor Your use of them so that Dragonchain may assess
            the performance and capabilities of the Site and the Products. You (and each Authorized User)
            hereby grant Dragonchain the right to do so. While Dragonchain may monitor use of the Site and the
            Products, doing so does not (a) create any obligation by Dragonchain to report the results
            thereof; (b) constitute notice to Dragonchain of any fault or error in the Site or the Products or
            a violation of the Terms, Additional Terms; or (d) limit Dragonchain’s remedies for any violation
            of any of the above.
          </p>

          <Title tag="h3">Content</Title>
          <p>
            The Site and the Products contain content created and collected by Dragonchain, some of which is
            provided by third parties and Your rights to use such materials may be subject to additional terms
            and conditions. You are responsible for reviewing such terms and conditions and complying with
            them. Dragonchain does not make any warranty, express or implied, regarding any such third-party
            content.
          </p>

          <Title tag="h3">Availability</Title>
          <p>
            During the Subscription Term, Dragonchain will make reasonable commercial efforts to make the Site
            available to You 24 hours a day, 7 days a week except for planned downtime, during any period You
            are authorized to use the Products. However, You acknowledge that access to the Site depends on
            services provided by third parties that are outside Dragonchain’s control. Dragonchain makes no
            warranty regarding the availability of the Site or Products and You waive and release Dragonchain
            from any claims based on Your inability to access or use them. Dragonchain is providing access to
            the Site and the Products only. You are responsible for the acquisition, operation, and
            maintenance of all computer hardware and software and all communications and network connections
            necessary to access and use the Products and for paying all associated costs and expenses.
          </p>

          <Title tag="h3">Security</Title>
          <p>
            Dragonchain has contracted with a third party vendor (“Host”) to host the Site and Your Account.
            The Host has committed to implementing industry standard technical and security measures to
            protect the Site and Your data from unauthorized access.
          </p>

          <Title tag="h3">Our Proprietary Rights</Title>
          <p>
            All logos, trademarks, button icons, images, text, graphics, videos, forms, publications and other
            content used in connection with the Site and the Products are exclusively owned and controlled or
            licensed by Dragonchain and protected by U.S. intellectual property laws. Distribution,
            retransmission, or modification of the content is strictly prohibited. Further, You shall not
            remove or obscure any patent, trademark, copyright or other proprietary notices on or incorporated
            into the Products or permit any Authorized User to do so.
          </p>

          <Title tag="h3">Confidentiality</Title>
          <p>
            The following provisions will apply if there is no existing non-disclosure agreement between You
            and Dragonchain. You will maintain the confidentiality of all information related to the Software
            and Services and all other information designated by Dragonchain as “Confidential Information”
            with at least the same degree of care that You use to protect Your own confidential and
            proprietary information, but no less than a reasonable degree of care under the circumstances. You
            will not disclose the Confidential Information to any employees or to any third parties except to
            Your employees who have a need to know and who agree to abide by nondisclosure terms at least as
            comprehensive as those set forth herein; provided that You will be liable for breach by any such
            entity. For the purposes of this Agreement, the term “employee” will include Your independent
            contractors, who have signed confidentiality agreements with You. You will not make any copies of
            the Confidential Information except as necessary for You or Your employees with a need to know.
            Any copies which are made will be identified as belonging to Dragonchain and marked
            “confidential”, “proprietary” or with a similar legend. You will not be liable for the disclosure
            of any Confidential Information which You can establish (a) was generally made available by
            Dragonchain publicly or to third parties by Dragonchain without restriction on disclosure; (b) You
            rightfully received from a third party without obligation of confidentiality; © was rightfully
            known to You without any limitation on disclosure prior to Your receipt from Dragonchain; (d) was
            independently developed by You or Your employees without use of or reference to Dragonchain
            Confidential Information; or (e) was required to be disclosed in accordance with applicable laws,
            regulations, court, judicial or other government order, provided that You will give Dragonchain
            reasonable notice prior to such disclosure and will comply with any applicable protective order.
          </p>

          <Title tag="h3">Disclaimer of Warranties and Release</Title>
          <p>
            DRAGONCHAIN PROVIDES THE SITE AND THE PRODUCTS TO YOU ON AN “AS IS” AND “AS AVAILABLE” BASIS.
            DRAGONCHAIN MAKES NO REPRESENTATIONS ABOUT THE AVAILABILITY OF YOUR DATA, THE SITE OR THE
            PRODUCTS. DRAGONCHAIN DISCLAIMS TO THE FULLEST EXTENT PERMISSIBLE BY LAW, AND YOU WAIVE, ALL
            WARRANTIES OF ANY KIND REGARDING THE SITE AND/OR THE PRODUCTS, WHETHER EXPRESS, IMPLIED, OR
            STATUTORY, INCLUDING WITHOUT LIMITATION THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
            PARTICULAR PURPOSE AND NON-INFRINGEMENT. FURTHER, TO THE EXTENT PERMITTED UNDER APPLICABLE LAWS,
            YOU (AND EACH AUTHORIZED USER) HEREBY RELEASE DRAGONCHAIN FROM ANY AND ALL CLAIMS OR LIABILITY
            RELATED TO YOUR USE OF THE SITE OR THE PRODUCTS. NONE OF THE CONTENT, THE SITE OR PRODUCTS ARE
            WARRANTED TO BE UNINTERRUPTED, TIMELY, COMPLETELY SECURE OR ERROR-FREE, AND DRAGONCHAIN MAKES NO
            WARRANTY THAT CONTENT WILL BE ACCURATE, CURRENT OR RELIABLE OR THAT THE QUALITY OF THE PRODUCTS,
            INFORMATION OR OTHER MATERIAL PURCHASED OR OBTAINED THROUGH THE SITE WILL MEET YOUR EXPECTATIONS.
            DRAGONCHAIN DISCLAIMS ANY RESPONSIBILITY FOR THE DELETION, FAILURE TO STORE, MIS-DELIVERY, OR
            UNTIMELY DELIVERY OF ANY INFORMATION OR MATERIAL OR FOR LOSS OF OR ERRORS IN MAINTAINING YOUR
            DRAGON BALANCE. YOU ASSUME THE ENTIRE RISK OF LOSS AND DAMAGE DUE TO YOUR USE OF THE SITE AND/OR
            PRODUCTS AND THE USE THEREOF BY AUTHORIZED USERS. FURTHER, YOU UNDERSTAND THAT DRAGONCHAIN MAKES
            NO REPRESENTATION OR GUARANTEE CONCERNING ANY OUTCOME ASSOCIATED WITH YOUR USE OF THE SITE OR
            PRODUCTS OR THE USE THEREOF BY AUTHORIZED USERS. DRAGONCHAIN MAKES NO WARRANTY REGARDING SECURITY
            AND SHALL NOT BE LIABLE FOR ANY BREACH OF SECURITY OR UNAUTHORIZED ACCESS TO THE SITE OR YOUR
            DATA.
          </p>

          <Title tag="h3">Exclusive Remedy</Title>
          <p>
            Your sole remedy in the event Dragonchain breaches any obligations under these Terms or the
            Additional Terms or the Products fail to operate or are otherwise defective shall be to terminate
            use of the Products pursuant to “Our Remedies” Section below.
          </p>

          <Title tag="h3">Limitation of Liability</Title>
          <p>
            YOUR USE OF THE SITE AND/OR THE PRODUCTS IS ENTIRELY AT YOUR SOLE RISK. NEITHER DRAGONCHAIN, ITS
            OWNERS, DIRECTORS, OFFICERS, EMPLOYEES, AND AGENTS SHALL BE LIABLE FOR ANY DIRECT, INDIRECT,
            INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY OR PUNITIVE DAMAGES OR LOSSES (INCLUDING WITHOUT
            LIMITATION LOSS OF PROFITS, GOODWILL, USE, YOUR CONTENT, DRAGONS OR OTHER INTANGIBLE LOSSES),
            WHETHER BASED IN CONTRACT, TORT, STRICT LIABILITY, OR OTHERWISE, WHICH YOU MAY INCUR IN CONNECTION
            WITH THE USE OF, OR INABILITY TO USE, THE SITE OR THE PRODUCTS, EVEN IF Dragonchain HAS BEEN
            ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR LOSS.
          </p>

          <Title tag="h3">Indemnification</Title>
          <p>
            You shall indemnify, defend and hold Dragonchain and its officers, directors, shareholders,
            principals and agents harmless from any and all losses, costs, claims and liabilities asserted
            against or incurred by Dragonchain arising out of or due to Your breach of the Terms, Additional
            Terms; Your use or misuse of the Site and/or the Products; Your violation of any law or the rights
            of any third party; and any act or omissions by any Authorized User.
          </p>

          <Title tag="h3">Our Remedies</Title>
          <p>
            You acknowledge that any attempt to use, copy, license, or transfer any portion of the Site, the
            Products, or other Dragonchain property in a manner contrary to the Terms (and, as applicable, the
            Additional Terms) or otherwise in derogation of Dragonchain’s rights would irreparably injure
            Dragonchain, which injury could not adequately be compensated by money damages. Accordingly, You
            agree that Dragonchain may seek and obtain injunctive relief from the breach or threatened breach
            of any such provision, requirement or covenant hereof, in addition to and not in limitation of any
            other equitable or legal remedies available.
          </p>

          <Title tag="h3">Termination/Cancellation</Title>
          <p>
            Dragonchain may terminate Your Account (and Your right to access the Software and/or Services) for
            Your breach of any provision of these Terms and, as applicable the EULA and/or TOS, or any other
            reason. Likewise, Dragonchain may terminate or suspend access to the Products by any Authorized
            User if Dragonchain reasonably believes he/she is using the Site or Products in a manner that
            violates these Terms, the Additional Terms. Further, Dragonchain may, in its sole discretion,
            discontinue any or all of the features associated with the Products. The following provisions of
            these Terms of Use shall survive expiration, termination or cancellation of Your Account.
          </p>

          <Title tag="h3">Assignment</Title>
          <p>
            You may not assign, license, transfer, mortgage, sub-contract or otherwise transfer any of Your
            rights or obligations under these Terms, Additional Terms without Dragonchain’s prior written
            consent. Any transfer without the Dragonchain’s written consent shall constitute a breach of the
            Terms and shall be void and of no effect.
          </p>

          <Title tag="h3">Legal Review</Title>
          <p>
            The Terms (and those in the Additional Terms) contain important provisions concerning Your legal
            rights and obligations. Dragonchain recommends that You seek outside legal counsel for such
            purpose. By accepting these Terms, You represent that You have had the opportunity to seek outside
            legal counsel and accept the terms hereof willingly and with a full understanding of Your rights
            and obligations hereunder.
          </p>

          <Title tag="h3">Jurisdiction</Title>
          <p>
            You irrevocably consent to the sole and exclusive jurisdiction of and venue in the state and
            federal courts located in Bellevue, Washington, in connection with any suit or action in aid of
            arbitration and/or for any provisional remedy including, but not limited to, any preliminary
            injunctive relief. These Terms and the terms of the Additional Terms will be governed, construed
            and enforced in accordance with the laws of the State of Washington, without giving effect to its
            choice of law rules.
          </p>

          <Title tag="h3">Dispute Resolution</Title>
          <p>
            You expressly acknowledge and agree that the sole and exclusive forum for resolving any
            controversy or claim arising out of or relating to these Terms, the terms of the Additional Terms
            and Your use of the Site and the Products shall be binding arbitration, under the American
            Arbitration Association (“AAA”) commercial arbitration rules, then in effect. You also agree that
            the AAA optional rules for emergency measures of protection shall also apply to the proceedings.
            Judgment on the award rendered by the arbitrators may be entered in any court having jurisdiction
            thereof. Unless Dragonchain and You mutually agree on another location, all arbitrations shall be
            held in Seattle, Washington. In the event that this provision is held invalid or unenforceable,
            the remaining portions of this section shall remain in full force and effect. YOU AGREE NOT TO
            BRING OR PARTICIPATE IN A CLASS OR REPRESENTATIVE ACTION, PRIVATE ATTORNEY GENERAL ACTION OR
            COLLECTIVE ARBITRATION, EVEN IF AAA’S RULES ALLOW FOR SUCH.
          </p>

          <Title tag="h3">Attorneys’ Fees</Title>
          <p>
            If any legal action is brought by any party against the other regarding the subject matter of
            these Terms, the Additional Terms, the Site and/or the Products, the prevailing party shall be
            entitled to recover, in addition to any other relief, reasonable attorneys’ fees and expenses.
          </p>

          <Title tag="h3">Severability</Title>
          <p>
            If any provision of these Terms, the Additional Terms are found by a court to be void, invalid or
            unenforceable, the same shall be reformed to comply with applicable law and the intentions of the
            parties as set out herein to the extent possible, or stricken if not so conformable, so as to not
            affect the validity or enforceability of such terms.
          </p>

          <Title tag="h3">Updates</Title>
          <p>
            Dragonchain may update these Terms from time to time and may do so by notifying You through the
            email address linked to Your Account or as otherwise provided herein.
          </p>

          <Title tag="h3">Contact Information</Title>
          <p>
            If You have any questions or concerns regarding these Terms or the Additional Terms, please
            contact Dragonchain at info@dragonchain.com.
          </p>
        </Block>
      </Layout>
    </PageLayout>
  );
};

export default Terms;
